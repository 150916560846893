<template>
  <div id="container" v-if="fetch_data">

    <section id="event">
      <div class="wrap">
        <h2 class="sub_ttl"><span>イベント参加者専用スペース</span></h2>
        <div class="cts_box" v-for="space in fetch_data.spaces" :key="space.id">
          <h3 class="min_ttl">{{ space.title }}</h3>
          <ul class="workshop_sns_list flex">
            <li v-for="room in space.rooms" :key="room.id">
              <router-link
                  :to="{ name: 'SnsSpace', params: {nav_category: 'workshop', space_id: space.id, room_id: room.id} }"
                  v-bind:class="{disabled: !room.user_join}">{{ room.title }}
              </router-link>
              <p class="red" v-if="!room.user_join">参加申込後に専用スペースも参加可能になります</p>
            </li>
          </ul>
        </div>

        <div class="cts_box">
          <div class="btn_wrap">
            <router-link to="/" class="submit basic arrow">トップページにもどる</router-link>
          </div>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import Libraries from '@/components/Libraries.vue'

export default {
  mixins: [Libraries],
  components: {},
  data() {
    return {
      fetch_data: null
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.startAjax()
      this.axios
          .get(`${this.env.api_base_url}sns/workshops.json`, {})
          .then(response => {
            this.fetch_data = response.data
          })
          .catch((response) => {
            this.errorAjax(response)
          })
          .finally(() => {
            this.finishAjax()
          })
    }
  }
}
</script>
